import React from 'react';
import { useParams, Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { Box,
    Button,
    Typography,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Alert,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    
} from '@mui/material';

import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

import ReactTimeAgo from 'react-time-ago'
import { BrandContext } from '../../App';
import heropic1 from '../../assets/man_with_paper.png'

import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorIcon from '@mui/icons-material/Error';

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import Moment from 'moment';

export default function DashboardMarketing() {
    const { brand, user, authToken } = React.useContext(BrandContext);
    const [leaders, setLeaders] = React.useState(null);

    Moment.locale('en');
    
    function updateLeaders() {
        fetch(`${brand.api_base_url}/leaders`, {
            headers: { 'Authorization': `Token ${authToken}` },
        })
            .then((response) => response.json())
            .then((data) => {
                setLeaders(data);
                console.log(data);
                document.title = `Leaderboard - ${brand.name}`;
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
    }

    React.useEffect(() => {
        updateLeaders();
        
    },[authToken]);
    
    return (
        <React.Fragment>
                <Typography variant="h4">Leaderboard for Week {Moment().format("w")}</Typography>
                <Typography variant="body1">The leaderboard displays your team members with the highest GuruNow Performance Score&trade; for the current week.</Typography>
                {leaders && leaders.map((l, index) => (
                    <Card sx={{ display: 'flex', m: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <CardMedia
                                component="img"
                                sx={{ height: 160 }}
                                image={l.image}
                                
                                title=""
                            />
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography variant="h5">{l.first_name} {l.last_name}</Typography>
                                <Typography variant="body1"></Typography>
                            </CardContent>
                        </Box>
                    </Card>
                ))}
        </React.Fragment>
    );
}