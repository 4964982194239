import React from 'react';
import { Box,
    Button,
    Typography,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Alert,
    TextField,
    CircularProgress,
    Snackbar,
    Tooltip,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import ReactTimeAgo from 'react-time-ago'
import { BrandContext } from '../../App';
import heropic1 from '../../assets/man_with_paper.png'

import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorIcon from '@mui/icons-material/Error';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import EditIcon from '@mui/icons-material/Edit';

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)

export default function DashboardScenarios() {
    const { brand, user, authToken } = React.useContext(BrandContext);
    const [learningPaths, setLearningPaths] = React.useState([]);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const timeAgo = new TimeAgo('en-US');


    function updateLearningPaths() {
        fetch(`${brand.api_base_url}/learningpaths`, {
            headers: { 'Authorization': `Token ${authToken}` },
        })
            .then((response) => response.json())
            .then((data) => {
                setLearningPaths(data);
                console.log(data);
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
    }

    React.useEffect(() => {
        document.title = `Guru Paths - ${brand.name}`;
    }, [user]);

    React.useEffect(() => {
        updateLearningPaths();
        
    },[authToken]);

    function get_num_scenarios(path) {
        var scen_num = 0;

        path.steps.forEach((step) => {
            if(step.scenario != null) {
                scen_num = scen_num + 1;
            } else {
                scen_num = scen_num + step.scenariogroup.scenarios.length
            }
        });

        return scen_num;
    }
    
    return (
        <React.Fragment>
            <Snackbar
                open={snackbarMessage}
                autoHideDuration={5000}
                onClose={() => { setSnackbarMessage(''); }}
                message={snackbarMessage}
            />
            <Typography variant="h4">Guru Paths</Typography>
            <Paper sx={{ m: 3, p: 2, display: 'flex' }}>
            {learningPaths.length > 0 && learningPaths.map((lpath, index) => (
                <>
                <Card sx={{ maxWidth: 345, m: 1 }}>
                    <CardMedia
                        sx={{ height: 140 }}
                        image={lpath.image}
                        title=""
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        {lpath.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {lpath.prerequisites && lpath.prerequisites.length > 0 ? (
                                <>
                                    {lpath.prerequisites.length} certification required to unlock this learning path
                                </>
                            ) : (
                                <>
                                    {get_num_scenarios(lpath)} scenario{get_num_scenarios(lpath) == 1 ? '' : 's'} to complete for the <b>{lpath.leads_to.name}</b> certification
                                </>
                            )}
                        
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center'}}>
                        {lpath.prerequisites && lpath.prerequisites.length > 0 ? (
                                <>
                                    <Button size="small" href={`/dashboard/learningpath/${lpath.id}`} variant="outlined" disabled>Read more</Button>
                                </>
                            ) : (
                                <>
                                    <Button size="small" href={`/dashboard/learningpath/${lpath.id}`} variant="outlined">Read more</Button>
                                    
                                </>
                            )}
                    </CardActions>
                    </Card>
                </>
            ))}
            </Paper>
        </React.Fragment>
    );
}