import React from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
    Box,
    Button,
    Typography,
    Paper,
    TextField,

} from '@mui/material';
import { BrandContext } from '../App';
import navbg from '../assets/GNBackground.png'

import { Gauge, gaugeClasses } from '@mui/x-charts';

export function DemoDebrief() {
    const { brand, user, authToken } = React.useContext(BrandContext);
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState("");

    const navigate = useNavigate();

    const { simulation_id } = useParams();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if(name=='name') {
            setName(value);
        }
        if(name=='email') {
            setEmail(value);
        }

    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        

        fetch(`${brand.api_base_url}/leads`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'name': name, 'email': email })
        })
            .then((response) => response.json())
            .then((data) => {
                // Show feedback
                navigate('/demofeedback/'+simulation_id)

            }).then()
            .catch((err) => {
                console.log(err.message);
            });
    };

    

    React.useEffect(() => {
        document.title = `Simulation Feedback - ${brand.name}`;
    }, [user]);


    return (
        <React.Fragment>
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: `url(${navbg})`
                }}
            >
                <Paper
                    sx={{
                        m: 2,
                        p: 2,
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        textAlign: 'center',
                        height: '99%',
                        width: '75%',
                        flexDirection: 'column',
                        position: 'relative', // Position Paper relative for overlay positioning
                        overflow: 'hidden' // Hide overflow if the gradient extends beyond the component
                    }}
                >
                    {/* Overlay with gradient */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 5%, #77bbe8 40%)',
                            pointerEvents: 'none',
                            zIndex: 10,
                        }}
                    />
                    {/* Content of Paper */}
                    {(
                        <Box sx={{ mt: 3 }}>
                            <Typography variant="h4">Your GuruNow&trade; Performance Score</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }} >
                                {['#6de5e0', '#76bae7', '#5389e5'].map((color, index) => (
                                    <Gauge
                                        key={index}
                                        width={300}
                                        height={300}
                                        value={25*index}
                                        startAngle={-90}
                                        endAngle={90}
                                        sx={{
                                            [`& .${gaugeClasses.valueText}`]: {
                                                fontSize: 40,
                                                transform: 'translate(0px, 0px)',
                                            },
                                            [`& .${gaugeClasses.valueArc}`]: {
                                                fill: color,
                                            },
                                        }}
                                        text={() => ''}
                                    />
                                ))}
                            </Box>
                        </Box>
                    )
                    }
                    <form onSubmit={handleSubmit} onChange={handleInputChange} style={{
                        display: "flex",
                        position: 'absolute',
                        flexDirection: "column",
                        backgroundColor: 'white',
                        padding: '3rem',
                        borderRadius: '20px',
                        bottom: '33%',
                        gap: "1rem",
                        width: '40%',
                        zIndex: 100
                    }}>
                        <Typography variant="h6">Enter your contact details to see your results:</Typography>
                        <TextField
                            label="Your name"
                            name="name"
                            variant="outlined"
                            fullWidth
                            required
                            value={name}
                        />
                        <TextField
                            label="Work email"
                            name="email"
                            type="email"
                            variant="outlined"
                            fullWidth
                            required
                            value={email}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{
                                marginTop: "1rem",
                                padding: "0.75rem",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: 'black',
                                backgroundColor: "#6de5e0",
                            }}
                        >
                            Show Results
                        </Button>
                    </form>
                    {message && <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: "1rem" }}>{message}</Typography>}
                </Paper>
            </Box>
        </React.Fragment >
    );
}
