import React from 'react';
import { useParams, Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import {
    Box,
    Button,
    Typography,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Alert,
    Snackbar,

} from '@mui/material';
import ReactTimeAgo from 'react-time-ago'
import { BrandContext } from '../App';
import navbg from '../assets/GNBackground.png'

import VrpanoIcon from '@mui/icons-material/Vrpano';
import GroupIcon from '@mui/icons-material/Group';

import { LineChart } from '@mui/x-charts/LineChart';
import Moment from 'moment';

import NewWindow from 'react-new-window'

import ReactPlayer from 'react-player'

export function DemoBrief() {
    const { brand, user, authToken } = React.useContext(BrandContext);
    const [simulationId, setSimulationId] = React.useState(null);
    const [scenarioData, setScenarioData] = React.useState(null);
    const [simulationHistory, setSimulationHistory] = React.useState(null);
    const [simulationLineChartData, setSimulationLineChartData] = React.useState(null);
    const [simulationCallURL, setSimulationCallURL] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');

    const { scenario_id } = useParams();
    const navigate = useNavigate();

    Moment.locale('en');

    function startScenario() {
        setLoading(true);

        fetch(`${brand.api_base_url}/start_simulation_demo`, {
            method: 'POST',
            body: JSON.stringify({ 'scenario_id': scenario_id })
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['id']) {
                    setSimulationCallURL(data['webrtc_url']);
                    setSimulationId(data['id']);
                    setLoading(false);
                } else {
                    setSnackbarMessage('Starting simulation failed');
                    setLoading(false);
                }
                console.log(data);
            }).then()
            .catch((err) => {
                setSnackbarMessage('Starting simulation failed');
                console.log(err.message);
                setLoading(false);
            });
    }

    React.useEffect(() => {
        document.title = `Simulate - ${brand.name}`;
    }, [user]);

    React.useEffect(() => {
        fetch(`${brand.api_base_url}/demo_scenario?scenario_id=${scenario_id}`, {
        })
            .then((response) => response.json())
            .then((data) => {
                setScenarioData(data);
            }).then()
            .catch((err) => {
                console.log(err.message);
            });

    }, [authToken, scenario_id]);

    function endSimulation() {
        fetch(`${brand.api_base_url}/rtc_end_demo_simulation`, {
            method: 'POST',
            body: JSON.stringify({ 'simulation_id': simulationId })
        })
            .then((response) => response.json())
            .then((data) => {
                navigate('/demodebrief/'+simulationId);
                console.log(data);
            }).then()
            .catch((err) => {
                setSnackbarMessage('Ending simulation failed');
                console.log(err.message);
                setLoading(false);
            });
    }


    return (
        <React.Fragment>
            <Snackbar
                open={snackbarMessage ? true : false}
                autoHideDuration={5000}
                onClose={() => { setSnackbarMessage(''); }}
                message={snackbarMessage}
            />
            {scenarioData != null && (
                <>
                <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', background: `url(${navbg})` }} >
                    {simulationId == null ? (
                        <>
                            
                                <Paper sx={{
                                    m: 2,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    flexDirection: 'column',
                                    p: 1
                                }}>
                                    {scenarioData.briefing_video ? (
                                        <>
                                            <ReactPlayer
                                            url={scenarioData.briefing_video}
                                            controls={true}
                                            width='100%'
                                        />
                                    </>
                                    ) : (
                                        <>
                                            <Typography variant="h4" sx={{ fontWeight: 'bold', m: 2 }}>{scenarioData.name}</Typography>
                                        </>
                                    )}
                                    
                                    <Paper sx={{ m: 2, p: 2, display: 'flex', justifyContent: 'center', alignContent: 'center', width: '55vw' }}>
                                        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{scenarioData.long_description}</Typography>
                                    </Paper>
                                    <Box>
                                        <Button onClick={startScenario} variant="contained" size="large" sx={{ m: 2, p: '20px 75px', borderRadius: '50px' }} startIcon={<GroupIcon />}>Start Demo</Button>
                                    </Box>
                                </Paper>
                            
                        </>
                    ) : (
                        <>
                            <Typography variant="h5" sx={{ textAlign: 'center', m: 3 }}>Please allow popups to start the simulation.</Typography>
                            <NewWindow
                                url={simulationCallURL}
                                center="screen"
                                onUnload={endSimulation}
                                features={{ width: 1400, height: 800 }}
                            />
                        </>
                    )}
                </Box>
                </>
            )}


        </React.Fragment>
    );
}