import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';


import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)

const root = ReactDOM.createRoot(document.getElementById('root'));
const doExTheme = createTheme({
  palette: {
    type: 'dark',
    primary: { main: '#00ADEF', contrastText: '#ffffff' },
    secondary: { main: '#383838', contrastText: '#ffffff' }
  },
  shape: {
    
  },
  typography: {
    fontFamily: [
      'Roboto Condensed',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },

});

doExTheme.typography.body1 = { fontFamily: ['Montserrat', 'Arial', 'sans-serif'] }
doExTheme.typography.body2 = { fontFamily: ['Montserrat', 'Arial', 'sans-serif'] }

root.render(
  <React.StrictMode>
    <ThemeProvider theme={doExTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
