import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Box, Divider, CircularProgress, Chip, Typography, Fade, Autocomplete, TextField, Select, MenuItem, FormControl, InputLabel, FormHelperText, Alert } from '@mui/material';
import { BrandContext } from '../App.js';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Delayed from './Delayed.ts'
import countryList from 'react-select-country-list'

function range(start, end) {
    if(start === end) return [start];
    return [start, ...range(start + 1, end)];
}

const industries = [
    "3D Printing",
    "Artificial Intelligence",
    "Autonomous Vehicle Technology",
    "Biotechnology",
    "Blockchain Technology",
    "Cloud Computing Services",
    "Cybersecurity",
    "Data Analytics",
    "E-commerce Platforms",
    "Fintech (Financial Technology)",
    "Gaming and Interactive Media",
    "HealthTech (Healthcare Technology)",
    "Internet of Things (IoT)",
    "Renewable Energy Technologies",
    "Robotics",
    "Software Development",
    "Aerospace Technology",
    "Virtual Reality and Augmented Reality",
    "Wearable Technology",
    "Web Design",
    "------",
    "Agriculture and Farming",
    "Automotive Manufacturing",
    "Banking and Finance",
    "Construction and Engineering",
    "Consumer Goods and Retail",
    "Education and Training",
    "Energy and Utilities",
    "Fashion and Apparel",
    "Food and Beverage",
    "Healthcare and Pharmaceuticals",
    "Hospitality and Tourism",
    "Information Technology",
    "Insurance",
    "Legal Services",
    "Media and Entertainment",
    "Mining and Metals",
    "Real Estate",
    "Telecommunications",
    "Transportation and Logistics",
    "Waste Management and Environmental Services",
];


export function getSignupSteps(brand, signupValues={}, setSignupValues=null, signupValueErrors=[], checkedAddressStatus='') {

    const autocompleteFilterOptions = createFilterOptions({
        limit: 5
        });
    
    const valueChangeHandler = e => {
        setSignupValues({...signupValues, [e.target.name]: e.target.value || ''});
        }
    
    const signupSteps =
        [
            {
                'title': 'Sign-up here',
                'content': (
                    <React.Fragment>
                        <Typography variant="h5" sx={{ mt: 4, mb: 2}}>Sign-up here</Typography>
                        
                        
                    </React.Fragment>)
            },
            {
                'title': 'Step 2',
                'content': (
                    <React.Fragment>
                        
                    </React.Fragment>)
            },
        ];

    return signupSteps;
}

