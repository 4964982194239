import * as React from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress, Typography, Button, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Stack, Paper, Alert, Box, Link } from '@mui/material';
import { BrandContext } from '../App';
import landingbg from '../assets/landingbg.png'
import heropic from '../assets/man_with_laptop.png'
import { LandingLayout } from '../components/LandingLayout';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function ResetPasswordPage(props) {
    const [showPassword, setShowPassword] = React.useState(false);
    const [loginCredentials, setLoginCredentials] = React.useState({});
    const [errorMessage, setErrorMessage] = React.useState("");
    const [validInputs, setValidInputs] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [validResetToken, setValidResetToken] = React.useState(false);
    const { brand, authToken, saveAuthToken } = React.useContext(BrandContext);

    const navigate = useNavigate();
    const [qParams] = useSearchParams();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleLogin = (e) => {

        e.preventDefault();
        
        if(!validInputs) {
            setErrorMessage("Password reset failed.");
        }

        fetch(`${brand.api_base_url}/reset_password`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({...loginCredentials, ['token']: qParams.get('token')})
        })
            .then((response) => response.json())
            .then((data) => {
                if(Object.keys(data).includes('status')) {
                    // success
                    navigate('/login?after=reset');

                } else {
                    setErrorMessage("Sorry, the password reset failed. Please try again.");
                }
            }).then()
            .catch((err) => {
                console.log(err.message);
                setErrorMessage(err.message);
            });


    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleLoginCredentialChange = (e) => {
        setLoginCredentials({...loginCredentials, [e.target.name]: e.target.value});
    };

    React.useEffect(() => {
        // Validate reset token only if loading and it hasn't been validated
        if(!loading || validResetToken) {
            return;
        }

        fetch(`${brand.api_base_url}/validate_password_reset_token`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'token': qParams.get('token')})
        })
            .then((response) => response.json())
            .then((data) => {
                if(Object.keys(data).includes('valid')) {
                    // success
                    setValidResetToken(true);
                    setLoading(false);

                } else {
                    setErrorMessage("Sorry, this password reset link has expired, or is invalid.");
                    setLoading(false);
                }
            }).then()
            .catch((err) => {
                console.log(err.message);
                setErrorMessage("Sorry, there was an unknown error resetting your password.");
                setLoading(false);
            });

     }, []);

    React.useEffect(() => {
        if(Object.keys(loginCredentials).includes('password') &&
           Object.keys(loginCredentials).includes('confirm_password') &&
           /.{8,}/.test(loginCredentials['password']) &&
           /.{8,}/.test(loginCredentials['confirm_password']) &&
           loginCredentials['password'] === loginCredentials['confirm_password']) {
            setValidInputs(true);
           } else {
            setValidInputs(false);
           }

      }, [loginCredentials]);

      if (loading===true)
      {
          return (
              <>
                  <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: 1, height: "100vh" }}
                      >
                      <CircularProgress />

                  </Stack>
              </>
          )
      }

    return (
        <LandingLayout>
            
            <Paper sx={{ maxWidth: {xs: 0.95, lg: 0.4 }, m: 1, mt: 5, p: 3, marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#fefefe', display: 'flex', justifyContent: 'space-evenly'}}>
                <Stack direction="column" sx={{ textAlign: 'left', display: 'flex', justifyContent: 'left', minWidth: '300px' }}>
                <Typography variant="h3" gutterBottom>{qParams.has('new') ? 'Create' : 'Reset'} your password</Typography>
                {errorMessage.length > 0 ? (<Alert severity="error" sx={{ mt: 2 }}>{errorMessage}</Alert>) : (
                    <form onSubmit={handleLogin}>
                    <FormControl sx={{ mt: 2, width: 1 }}>
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={handleLoginCredentialChange}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }   
                            label="Password"
                            
                        />
                    </FormControl>
                    <FormControl sx={{ mt: 2, width: 1 }}>
                        <InputLabel htmlFor="outlined-adornment-password2">Password again</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password2"
                            name="confirm_password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={handleLoginCredentialChange}
                            label="Password again"
                        />
                    </FormControl>
                    <Button type="submit" sx={{ mt: 4, height: '3rem' }} variant="contained" disabled={!validInputs} onClick={handleLogin}>Continue</Button>
                    </form>
                )}
                </Stack>
                <Box component="img" src={heropic} sx={{ height: '400px', display: {xs: 'none', xl: 'block' }}} />
            </Paper>
        </LandingLayout>
    );
}
