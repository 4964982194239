import React from 'react';
import { Box,
    Button,
    Typography,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Alert,
    
} from '@mui/material';
import ReactTimeAgo from 'react-time-ago'
import { BrandContext } from '../../App';
import heropic1 from '../../assets/man_with_paper.png'

import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorIcon from '@mui/icons-material/Error';

import { LineChart } from '@mui/x-charts/LineChart';

export default function DashboardMarketing() {
    const { brand, user, authToken } = React.useContext(BrandContext);
    const [settings, setSettings] = React.useState(null);
    
    function updateSettings() {
        fetch(`${brand.api_base_url}/settings`, {
            headers: { 'Authorization': `Token ${authToken}` },
        })
            .then((response) => response.json())
            .then((data) => {
                setSettings(data);
                console.log(data);
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
    }

    React.useEffect(() => {
        document.title = `Team - ${brand.name}`;
    }, [user]);

    React.useEffect(() => {
        updateSettings();
        
    },[authToken]);
    
    return (
        <React.Fragment>
            <Typography variant="h4">Your team</Typography>
            <Typography variant="h5">Total Score in August</Typography>
            <LineChart
                height={500}
                width={500}
                series={[
                    { data: [0, 1, 2, 3, 4], label: 'Total Score' },
                    { data: [0, 1, 2, 3, 4], label: 'EQ Score' },
                    { data: [0, 1, 2, 3, 4], label: 'Impact Score' },
                    { data: [0, 1, 2, 3, 4], label: 'Influence Score' },
                    ]}
                    xAxis={[{ scaleType: 'point', data: ['August 5th', 'August 12th', 'August 19th', 'August 26th'] }]}
            />
        </React.Fragment>
    );
}